<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {},
};
</script>

<style scoped lang='less'></style>
